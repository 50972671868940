//sticky nav
import domReady from '@wordpress/dom-ready';

function adjustNavbar() {
  const lastScrollTop = 80;
  const st = window.scrollY;

  if (st > lastScrollTop && 0 <= lastScrollTop) {
    document.body.classList.add('nav-sticky');
  } else {
    document.body.classList.remove('nav-sticky');
  }
}

domReady(() => {
  adjustNavbar();

  document.addEventListener('scroll', function () {
    adjustNavbar();
  });
});
